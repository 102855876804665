import { withTranslation } from 'react-i18next';

function ErrorVoucher(props) {
  const { t } = props;

  return (
    <div>
      <div>{t('Error.inexist')}</div>
    </div>
  );
}

export default withTranslation('common')(ErrorVoucher);
