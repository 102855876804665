import fetch from 'cross-fetch';

import { handleResponse } from '../helpers/handleResponse';

export const voucherService = {
  get,
};

function get(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetch(
    process.env.REACT_APP_API_URL + 'vouchers-prestations?id=' + id,
    requestOptions
  ).then(handleResponse);
}
