import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';

// translations
import common_fr from './translations/fr/common.json';
import common_en from './translations/en/common.json';
import common_us from './translations/us/common.json';
import common_es from './translations/es/common.json';
import common_it from './translations/it/common.json';
import common_pt from './translations/pt/common.json';
import common_de from './translations/de/common.json';
import common_nl from './translations/nl/common.json';
import common_no from './translations/no/common.json';
// containers
import Home from './containers/Home';
import Error404 from './containers/Error404';
import ErrorVoucher from './containers/ErrorVoucher';
import reportWebVitals from './reportWebVitals';

import { history } from './helpers/history';

import './styles/index.scss';

// process.env.NODE_ENV === "production" &&
Sentry.init({
  dsn: process.env.REACT_APP_PUBLIC_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

i18next.use(detector).init({
  interpolation: { escapeValue: false },
  fallbackLng: 'fr',
  resources: {
    en: {
      common: common_en,
    },
    fr: {
      common: common_fr,
    },
    es: {
      common: common_es,
    },
    us: {
      common: common_us,
    },
    it: {
      common: common_it,
    },
    pt: {
      common: common_pt,
    },
    de: {
      common: common_de,
    },
    nl: {
      common: common_nl,
    },
    no: {
      common: common_no,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/notfound" component={ErrorVoucher} />
        <Route component={Error404} />
      </Switch>
    </Router>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
