import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

// Components
import Header from '../components/Header';

// Services
import { voucherService } from '../services/voucher';

// Helpers
import {
  getQueryParam,
  getQuantity,
  renderDate,
  daysBetween,
} from '../helpers/helpers';

// Icons
import { FaHotel } from 'react-icons/fa';
import { IoAirplane } from 'react-icons/io5';
import { AiOutlineUnorderedList } from 'react-icons/ai';

function Home(props) {
  const { t } = props;
  const [state, setState] = useState({
    agency: {},
    client: {},
    items: [],
    project: {},
    supplier: {},
    lang: 'en',
    reservation: '',
  });

  useEffect(() => {
    let id = getQueryParam('id');
    voucherService.get(id).then((d) => {
      setState({
        agency: d.agency,
        client: d.client,
        items: d.items,
        project: d.project,
        supplier: d.supplier,
        lang: d.lang,
        reservation: d.reservation,
      });
      i18next.changeLanguage(d.lang ?? state.lang);
    });
  }, []);

  return (
    <div>
      <Helmet>
        <html lang={state.lang} />
        {state.reservation && state.supplier.company_name ? (
          <title>
            {state.supplier.company_name} - {state.reservation}
          </title>
        ) : (
          <title>Ezus - Vouchers</title>
        )}
        <meta name="description" content="Ezus - Vouchers" />
        <link rel="canonical" href="https://vouchers.ezus.io/" />
      </Helmet>
      <Header client={state.client} number={state.reservation} />
      <div className="wrapper-flex container">
        <div className="supplier">
          <h2 className="vertical-align">
            <FaHotel className="icon" />
            {t('Supplier.title')}
          </h2>
          <div className="content">
            <h3>{state.supplier.company_name}</h3>
            <div>
              {state.supplier.address &&
                state.supplier.address
                  .split(', ')
                  .map((label) => <span>{label}</span>)}
            </div>
            <span>{state.supplier.phone}</span>
          </div>
        </div>
        <div className="agency">
          <h2 className="vertical-align">
            <IoAirplane className="icon" />
            {t('Agency.title')}
          </h2>
          <div className="content">
            <h3>{state.agency.company_name}</h3>
            <div>
              <span>{state.agency.address_label}</span>
              <span>{state.agency.zip + ' - ' + state.agency.city}</span>
              <span>{state.agency.country}</span>
            </div>
            <span>{state.agency.phone}</span>
            <span>{state.agency.email}</span>
            <span>{state.agency.website}</span>
          </div>
        </div>
        <div className="product">
          <h2 className="vertical-align">
            <AiOutlineUnorderedList className="icon" />
            {t('Product.title')}
          </h2>
          {state.items.map((i) => (
            <div className="card">
              <h3>{i.title}</h3>
              <span className="info">{`${t('Product.quantity')}: ${getQuantity(
                i.quantity,
                state.project
              )}`}</span>
              {i.duration > 1 ? (
                <>
                  <span className="info">
                    {`${t('Product.date_start')}: `}
                    {state.project.is_without_date === 1
                      ? `${t('Product.day')} ${daysBetween(
                          state.project.date_start,
                          i.date_start
                        )}`
                      : renderDate(i.date_start, state.lang)}
                    {i.is_no_time === 0 && i.date_start.substring(10)}
                  </span>
                  <span className="info">
                    {`${t('Product.date_end')}: `}
                    {state.project.is_without_date === 1
                      ? ` ${t('Product.day')} ${daysBetween(
                          state.project.date_start,
                          i.date_end
                        )}`
                      : renderDate(i.date_end, state.lang)}
                    {i.is_no_time === 0 && i.date_end.substring(10)}
                  </span>
                </>
              ) : (
                <span className="info">
                  {`${t('Product.date')}: `}
                  {state.project.is_without_date === 1
                    ? ` ${t('Product.day')} ${daysBetween(
                        state.project.date_start,
                        i.date_start
                      )}`
                    : renderDate(i.date_start, state.lang)}
                  {i.is_no_time === 0 &&
                    `${i.date_start.substring(10)} - ${i.date_end.substring(
                      10
                    )}`}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withTranslation('common')(Home);
