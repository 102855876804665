import { withTranslation } from 'react-i18next';

function Header(props) {
  const { client, number, t } = props;

  return (
    <div className="header">
      <div className="wrapper-flex header-container">
        <div className="reservation">
          <h1>{t('Header.title')}</h1>
          <h2 className="number">
            {t('Header.reservation', { number: number })}
          </h2>
          <div className="status">{t('Header.completed_payment')}</div>
        </div>
        <div className="client vertical-align">
          <img className="logo" src={client?.logo} alt="client logo" />
          <div className="info">
            <h2>{t('Client.title')}</h2>
            <span>
              {client?.first_name} {client?.name}
            </span>
            {client?.type === 'entreprise' && (
              <span>{client?.company_name}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation('common')(Header);
