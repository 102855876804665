import moment from 'moment';

export function getQueryParam(param) {
  let rgx = new RegExp('[?&]' + param + '=([^&]+).*$');
  let returnVal = window.location.search.match(rgx);

  return returnVal === null ? null : returnVal[1];
}

export function getQuantity(quantity, project) {
  if (quantity === '-1') return project.people;
  else if (quantity === '-3') return project.days;
  else if (quantity === '-4') return project.nights;
  else return quantity;
}

export function renderDate(date = null, lang = 'fr', format = null) {
  const formatDtBase = {
    en: 'MM/DD/YYYY',
    es: 'DD/MM/YYYY',
    fr: 'DD/MM/YYYY',
  };
  const formatDt = format ? format : formatDtBase[lang];
  return date ? moment(date).format(formatDt) : formatDt;
}

export function daysBetween(dateStart, dateEnd) {
  return moment(dateEnd).diff(moment(dateStart), 'days') + 1;
}
