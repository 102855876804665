import { history } from '../helpers/history';

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok || !data || data.erreur !== 'false')
      history.push('/notfound');
    return data;
  });
}
